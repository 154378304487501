import React, { FunctionComponent } from 'react'
import Layout from '../components/LayoutBlog'
import { Container, Grid } from '../components/Common'
import { Post } from '../utils/models'
import { Card } from '../components/Card'
import { Reserve } from '../components/Reserve'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { SEO } from '../components/SEO'
import formatDate from '../utils/formatDate'

interface PostsPageProps {
  pageContext: {
    posts: Post[]
    postsPerPage: number
  }
  location: Location
}

const HomeContainer = styled(Container)`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 0.25fr;
  grid-column-gap: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-template-columns: 1fr;
  }
`

const PostsContainer = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'latest latest' '. .';
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: -30px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
    padding: 0;

    article {
      margin-bottom: 30px;
    }
  }
`

const Sidebar = styled.aside`
  width: 350px;
  padding-top: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 30px auto;
    border-top: 2px #e5eff5 solid;
    padding: 20px;
    width: 100%;
  }
`

const ArchiveLinkWrapper = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`

const ArchiveLink = styled(Link)`
  font-size: 0.8em;
  padding: 10px;
  border-radius: 0.3em;
  transition: background-color 0.5s;
  background-color: #f2f2f2;

  &:hover {
    background-color: #e6e6e6;
  }
`

const PostsPage: FunctionComponent<PostsPageProps> = ({
  pageContext,
  location,
}) => {
  const posts = pageContext.posts.slice(0, pageContext.postsPerPage)

  return (
    <Layout>
      <SEO
        title={
          'Blog da Vanna - Artigos, dicas, notícias e ajuda para cobranças instantâneas'
        }
        description={
          'No Blog da Vanna nós te informamos sobre todas as novidades sobre a Plataforma Vanna, cobranças instantâneas, Pix e muito mais.'
        }
        tags={[
          'duvidas Pix',
          'dicas Pix',
          'Plataforma Vanna',
          'cobranças instantâneas',
          'o que é Pix',
        ]}
        location={location}
        type={`Article`}
      />
      <HomeContainer>
        <PostsContainer>
          {posts.map((post, index) => (
            <Card
              title={post.frontmatter.title}
              path={post.frontmatter.path}
              featuredImage={
                post.frontmatter.featuredImage
                  ? post.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  : null
              }
              content={post.frontmatter.excerpt}
              key={index}
              meta={{
                time: post.frontmatter.created,
                timePretty: formatDate(post.frontmatter.createdPretty),
                tag:
                  post.frontmatter.tags.length > 0
                    ? post.frontmatter.tags[0]
                    : null,
              }}
              style={{ gridArea: index === 0 ? 'latest' : undefined }}
              halfImage={index === 0}
            />
          ))}
          <ArchiveLinkWrapper>
            <ArchiveLink to={`/blog/archive`}>Mais Posts</ArchiveLink>
          </ArchiveLinkWrapper>
        </PostsContainer>
        <Sidebar>
          <Reserve />
        </Sidebar>
      </HomeContainer>
    </Layout>
  )
}

export default PostsPage
